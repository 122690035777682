<template>
  <v-container fluid>
    <div
      class="mb-2 mt-2"
      style="font-size: 18px;font-weight: 400; margin-left:15px"
    >
      Add Form Fields
    </div>
    <v-divider horizontal />
    <div class="form-items">
      <div class="form-item-list" @click="addFormItem('full_name')">
        <v-icon>mdi-account</v-icon>Full Name
        <v-spacer></v-spacer>
        <span class="add-button">ADD</span>
      </div>

      <div class="form-item-list" @click="addFormItem('phone_number')">
        <v-icon>mdi-phone</v-icon>Phone Number
        <v-spacer></v-spacer>
        <span class="add-button">ADD</span>
      </div>

      <div class="form-item-list" @click="addFormItem('email')">
        <v-icon>mdi-email</v-icon>Email
        <v-spacer></v-spacer>
        <span class="add-button">ADD</span>
      </div>

      <div class="form-item-list" @click="addFormItem('radio_buttons')">
        <v-icon>mdi-radiobox-marked</v-icon>Radio Buttons
        <v-spacer></v-spacer>
        <span class="add-button">ADD</span>
      </div>

      <div class="form-item-list" @click="addFormItem('check_boxes')">
        <v-icon>mdi-checkbox-outline</v-icon>Check Boxes
        <v-spacer></v-spacer>
        <span class="add-button">ADD</span>
      </div>

      <div class="form-item-list" @click="addFormItem('text_box')">
        <v-icon>mdi-text-recognition</v-icon>Text Box
        <v-spacer></v-spacer>
        <span class="add-button">ADD</span>
      </div>

      <div class="form-item-list" @click="addFormItem('text_area')">
        <v-icon>mdi-form-textarea</v-icon>Text Area
        <v-spacer></v-spacer>
        <span class="add-button">ADD</span>
      </div>

      <div class="form-item-list" @click="addFormItem('drop_down')">
        <v-icon>mdi-form-dropdown</v-icon>Drop Down
        <v-spacer></v-spacer>
        <span class="add-button">ADD</span>
      </div>
    </div></v-container
  >
</template>
<script>
export default {
  props: {},
  components: {},
  data() {
    return {
      leads: [],
      tab: null,
    };
  },
  computed: {},
  mounted() {},
  watch: {},
  methods: {
    addFormItem(type) {
      console.log(type);
      this.$emit("addFormItem", type);
    },
  },
};
</script>
<style lang="scss" scoped>
.form-item-list {
  border-radius: 4px;
  display: flex;
  cursor: pointer;
  font-weight: 400;
  font-size: 14px;
  padding-left: 23px;
  padding-top: 10px;
  padding-bottom: 10px;
  gap: 8px;

  .add-button {
    display: none;
    font-weight: 500;
    font-size: 16px;
    padding-right: 10px;
  }
  &:hover {
    .add-button {
      display: block;
    }
    color: rgba(79, 174, 175, 1);
    background: rgba(79, 174, 175, 0.1);
    .theme--light.v-icon {
      color: rgba(79, 174, 175, 1) !important;
    }
  }
}
</style>
