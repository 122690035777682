<template>
  <v-container fluid>
    <v-form ref="form" v-model="valid" lazy-validation @submit.prevent="saveForm">
      <v-row>
        <v-col cols="12" md="12" lg="8">
          <v-card class="shadow px-8 pt-2 rounded-10 pb-0 rounded-border">
            <div
              class="mb-2 mt-2"
              style="font-size: 18px;font-weight: 400; margin-left:15px"
            >
              Form name:
              <v-col cols="12" sm="12" md="12" class="px-0">
                <v-text-field
                  clearable
                  outlined
                  background-color="#fff"
                  v-model="form_name"
                  required
                ></v-text-field>
              </v-col>
            </div>
            <div
              class="mb-2 mt-2"
              style="font-size: 18px;font-weight: 400; margin-left:15px"
            >
              Form Details:
              <v-col cols="12" class="px-0">
                <v-textarea
                  v-model="form_description"
                  name="description"
                  placeholder="Add short description..."
                  rows="3"
                  outlined
                  background-color="#fff"
                ></v-textarea>
              </v-col>
            </div>
          </v-card>

          <div
            class=" mt-4"
            style="font-size: 20px;font-weight: 500; margin-left:15px; margin-bottom:8px"
          >
            Form Fields
          </div>

          <div v-if="form_fields.length > 0">
            <LeadFormGeneric
              v-for="(lead, key) in form_fields"
              :key="key"
              :id="key"
              :type="lead.type"
              :options="lead.options"
              :is_required="lead.is_required"
              :field_name="lead.field_name"
              @deleteField="deleteField(key)"
              @setFieldName="setFieldName"
              @setIsRequired="setIsRequired"
              @moveUp="moveUp(key)"
              @moveDown="moveDown(key)"
              @addOption="addOption(key)"
              @deleteOption="deleteOption"
            />
          </div>
          <div v-else>
            <v-card class="shadow px-8 py-4 rounded-10 mt-1 rounded-border">
              <div
                class="mb-2 mt-2"
                style="font-size: 18px;font-weight: 400; display:flex; align-items:center; justify-content:center"
              >
                <span>Please add some fields</span>
              </div>
            </v-card>
          </div>
        </v-col>
        <v-col cols="12" md="4" lg="4" style="position:sticky; top:50px;">
          <v-card
            class="shadow px-8 pt-2 rounded-10 pb-0 rounded-border"
            style="position:sticky;"
          >
            <LeadFormActions @addFormItem="addNewFormItem" />
          </v-card>

          <v-card
            class="shadow px-8 pt-2 mt-10 rounded-10 pb-0 rounded-border"
            style="position:sticky;"
          >
            <div class="mb-2 mt-2" style="font-size: 18px;font-weight: 400; ">
              Choose Sequence
            </div>
            <v-divider horizontal />
            <v-select
              v-model="form_action"
              :items="actionsList"
              item-value="id"
              outlined
              :menu-props="{ bottom: true, offsetY: true }"
              background-color="#fff"
              item-text="name"
              :label="`Action sequence*`"
              required
            ></v-select>
          </v-card>
        </v-col>
      </v-row>
    <v-card-actions>
      <v-spacer></v-spacer>
      <v-btn class="ma-2 white--text teal-color"  type="submit">Save</v-btn>
    </v-card-actions>
    </v-form>
  </v-container>
</template>
<script>
import LeadFormGeneric from "@/components/Leads/LeadFormGeneric";
import LeadFormActions from "./LeadFormActions";
export default {
  props: {},
  components: { LeadFormGeneric, LeadFormActions },
  data() {
    return {
      valid: true,
      form_fields: [],
      form_action: null,
      tab: null,
      form_name: "",
      form_description: "",
      names: [
        { name: "full_name", value: "Full Name" },
        { name: "phone_number", value: "Phone Number" },
        { name: "email", value: "Email" },
        { name: "radio_buttons", value: "Radio Buttons" },
        { name: "check_boxes", value: "Check Boxes" },
        { name: "text_box", value: "Text Field" },
        { name: "text_area", value: "Text Area" },
        { name: "drop_down", value: "Dropdown list" },
      ],
    };
  },
  computed: {
    actionsList() {
      return this.$store.getters.getActionsList.data;
    },
  },
  mounted() {
    if (this.$store.getters.getActionsList.status == false) {
      this.$store.dispatch("loadActionsList");
    }

    this.getForm();
  },
  watch: {},
  methods: {
    getForm() {
      this.showLoader("Fetching details...");

      this.$http
        .get(`venues/leads/configuration/get-form`)
        .then((response) => {
          if (response.status == 200 && response.data.status == true) {
            if (response.data.data) {
              let data = response.data.data;
              this.form_fields = data.form_fields;
              this.form_name = data.form_name;
              this.form_description = data.form_description;
              this.form_action = data.lead_action_list_id;
            }
          }
          this.hideLoader();
        })
        .catch((error) => {
          this.hideLoader();
          this.errorChecker(error);
        });
    },
    saveForm(e) {
      // if (!this.$refs.form.validate()) {
      //   this.showError("Please fill all required fields");
      //   return;
      // }
      if (e != null) {
        e.preventDefault();
        if (!this.$refs.form.validate()) {
          this.showError("Please fill all required fields");
          return;
        }
      }
      if (!this.$refs.form.validate()) {
        this.showError("Please fill all required fields");
        return;
      }
      this.showLoader("Saving...");

      let data = {
        form_name: this.form_name,
        form_description: this.form_description,
        lead_action_list_id: this.form_action,
        form_fields: this.form_fields,
      };

      this.$http
        .post(`venues/leads/configuration/save-form`, data)
        .then((response) => {
          if (response.status == 200 && response.data.status == true) {
            this.getForm();
            this.showSuccess("Configuration saved successfully.");
          }
          this.hideLoader();
        })
        .catch((error) => {
          this.hideLoader();
          this.errorChecker(error);
        });
    },
    deleteField(key) {
      this.form_fields.splice(key, 1);
    },
    setFieldName(obj) {
      this.form_fields[obj.key].field_name = obj.value;
    },
    setIsRequired(obj) {
      this.form_fields[obj.key].is_required = obj.value;
    },
    addOption(key) {
      this.form_fields[key].options.push({ value: "" });
    },
    deleteOption(obj) {
      this.form_fields[obj.key].options.splice(obj.innerKey, 1);
    },

    moveUp(key) {
      if (this.form_fields.length <= 1) {
        return;
      }
      let fromIndex = key;
      let toIndex = key - 1;
      if (
        fromIndex < 0 ||
        fromIndex >= this.form_fields.length ||
        toIndex < 0 ||
        toIndex >= this.form_fields.length
      ) {
        console.error("Invalid index");
        return this.form_fields;
      }

      const elementToMove = this.form_fields.splice(fromIndex, 1)[0];
      this.form_fields.splice(toIndex, 0, elementToMove);
      return this.form_fields;
    },
    moveDown(key) {
      if (this.form_fields.length <= 1) {
        return;
      }
      let fromIndex = key;
      let toIndex = key + 1;
      if (
        fromIndex < 0 ||
        fromIndex >= this.form_fields.length ||
        toIndex < 0 ||
        toIndex >= this.form_fields.length
      ) {
        console.error("Invalid index");
        return this.form_fields;
      }

      const elementToMove = this.form_fields.splice(fromIndex, 1)[0];
      this.form_fields.splice(toIndex, 0, elementToMove);
      return this.form_fields;
    },
    addNewFormItem(type) {
      this.form_fields.push({
        type: type,
        field_name: this.getName(type),
        is_required: "0",
        options: [],
      });
    },
    getName(type) {
      let n = this.names.find((ele) => {
        return ele.name === type;
      });
      return n.value;
    },
  },
};
</script>
<style lang="scss" scoped>
.shadow {
  box-shadow: 0 4px 8px 0 rgba(70, 76, 136, 0.2) !important;
}
.rounded-border {
  border-radius: 10px;
}
.form-items {
  padding-top: 13px;
  padding-bottom: 18px;
}

.form-item-list {
  border-radius: 4px;
  display: flex;
  cursor: pointer;
  font-weight: 400;
  font-size: 14px;
  padding-left: 23px;
  padding-top: 10px;
  padding-bottom: 10px;
  gap: 8px;

  .add-button {
    display: none;
    font-weight: 500;
    font-size: 16px;
    padding-right: 10px;
  }
  &:hover {
    .add-button {
      display: block;
    }
    color: rgba(79, 174, 175, 1);
    background: rgba(79, 174, 175, 0.1);
    .theme--light.v-icon {
      color: rgba(79, 174, 175, 1) !important;
    }
  }
}
</style>
