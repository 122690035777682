<template>
  <v-container fluid>
    <v-row class="mb-2 mt-2">
      <v-btn class="back-button" elevation="0">
        <router-link :to="{ name: 'leadTracking' }">
          <v-icon size="18">mdi-arrow-left</v-icon>
        </router-link>
      </v-btn>
      <div style="font-size: 25px;font-weight: 600; margin-left:15px">
        Configuration
      </div>
    </v-row>

    <div>
      <v-tabs
        v-model="tab"
        background-color="white"
        centered
        class="mx-auto"
        icons-and-text
        style="width: fit-content"
      >
        <v-tabs-slider></v-tabs-slider>
        <v-tab href="#form-setup">
          Form Setup<v-icon>mdi-list-box-outline</v-icon>
        </v-tab>
        <v-tab href="#actions-list">
          Actions List<v-icon>mdi-format-list-bulleted</v-icon>
        </v-tab>
      </v-tabs>
    </div>

    <v-tabs-items v-model="tab" touchless>
      <v-tab-item value="form-setup">
        <div id="form-setup" class="section">
          <LeadFormSetup />
        </div>
      </v-tab-item>
      <v-tab-item value="actions-list">
        <div id="actions-list" class="section">
          <LeadFormActionsList />
        </div>
      </v-tab-item>
    </v-tabs-items>
  </v-container>
</template>
<script>
import LeadFormSetup from "./LeadFormSetup.vue";
import LeadFormActionsList from "./LeadFormActionsList.vue";
export default {
  props: {},
  components: { LeadFormSetup, LeadFormActionsList },
  data() {
    return {
      leads: [],
      tab: null,
    };
  },
  computed: {},
  mounted() {},
  watch: {},
  methods: {},
};
</script>
<style lang="scss" scoped>
.back-button {
  background: transparent !important;
  font-weight: 500;

  &,
  * {
    color: #112a46 !important;
  }
}
</style>
