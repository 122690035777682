<template>
  <v-container style="padding:0">
    <v-card
      class="shadow px-8 pt-4 rounded-10  pb-0 rounded-border"
      style="margin-top: 30px;"
    >
      <div class="mb-2 mt-2" style="font-size: 18px;font-weight: 400;">
        <div style="">
          {{ getName() }}
        </div>

        <v-row style="margin:0">
          <v-col cols="12" sm="6" md="6" class="px-0">
            <v-text-field
              v-if="type === 'full_name'"
              clearable
              outlined
              @change="setFieldName"
              dense
              required
              :rules="[(v)=> !!v || 'Field is required' ]"
              placeholder="John Doe"
              background-color="#fff"
              :value="field_name"
            ></v-text-field>
            <v-text-field
              v-if="type === 'phone_number'"
              clearable
              outlined
              @change="setFieldName"
              dense
              required
              :rules="[(v)=> !!v || 'Field is required' ]"
              label="Phone Number field"
              placeholder="+971 | 5XXXXXXXX"
              background-color="#fff"
              :value="field_name"
            ></v-text-field>
            <v-text-field
              v-if="type === 'email'"
              clearable
              outlined
              @change="setFieldName"
              dense
              required
              :rules="[(v)=> !!v || 'Field is required' ]"
              label="Email"
              placeholder="Email address field name"
              background-color="#fff"
              :value="field_name"
            ></v-text-field>
            <v-text-field
              v-else-if="type === 'text_box'"
              clearable
              outlined
              @change="setFieldName"
              dense
              required
              :rules="[(v)=> !!v || 'Field is required' ]"
              label="Write Question here"
              background-color="#fff"
              :value="field_name"
            ></v-text-field>
            <v-text-field
              v-else-if="type === 'radio_buttons'"
              clearable
              outlined
              @change="setFieldName"
              dense
              required
              label="Write Question here"
              background-color="#fff"
              :rules="[(v)=> !!v || 'Field is required' ]"
              :value="field_name"
            ></v-text-field>
            <v-text-field
              v-else-if="type === 'check_boxes'"
              clearable
              outlined
              @change="setFieldName"
              dense
              required
              label="Write Question here"
              :rules="[(v)=> !!v || 'Field is required' ]"
              background-color="#fff"
              :value="field_name"
            ></v-text-field>
            <v-text-field
              v-else-if="type === 'drop_down'"
              clearable
              outlined
              @change="setFieldName"
              dense
              required
              label="Write Question here"
              :rules="[(v)=> !!v || 'Field is required' ]"
              background-color="#fff"
              :value="field_name"
            ></v-text-field>
            <v-text-field
              v-else-if="type === 'text_area'"
              outlined
              @change="setFieldName"
              dense
              required
              :rules="[(v)=> !!v || 'Field is required' ]"
              label="Write Question here"
              background-color="#fff"
              :value="field_name"
            ></v-text-field>
          </v-col>
          <v-col cols="12" md="2">
            <v-checkbox
              :input-value="is_required"
              false-value="0"
              true-value="1"
              label="Required"
              class="ml-3"
              style="margin-top:5px !important"
              hide-details
              @change="setIsRequired"
            ></v-checkbox>
          </v-col>
          <v-col cols="12" md="2">
            <v-btn
              small
              block
              color="white"
              style="color:black; height:30px; margin-top:5px"
              depressed
              @click="deleteField()"
            >
              <v-icon left dark>
                mdi-trash-can-outline
              </v-icon>
              Remove
            </v-btn>
          </v-col>
          <v-col cols="12" md="1">
            <v-btn
              small
              block
              color="white"
              style="color:black; height:30px; margin-top:10px"
              depressed
              @click="moveUp()"
            >
              <v-icon dark>
                mdi-arrow-up-circle-outline
              </v-icon>
            </v-btn>
          </v-col>
          <v-col cols="12" md="1">
            <v-btn
              small
              block
              color="white"
              style="color:black; height:30px; margin-top:10px"
              depressed
              @click="moveDown()"
            >
              <v-icon dark>
                mdi-arrow-down-circle-outline
              </v-icon>
            </v-btn>
          </v-col>
          <v-row style="margin:0">
            <v-col cols="12" md="8" style="padding:0">
              <v-row v-if="options.length > 0">
                <v-col
                  cols="12"
                  md="6"
                  v-for="(option, key) in options"
                  :key="key"
                >
                  <div class="d-flex items-center">
                    <v-text-field
                      clearable
                      outlined
                      dense
                      required
                      :rules="[(v)=> !!v || 'Field is required' ]"
                      label="Option"
                      background-color="#fff"
                      v-model="option.value"
                      style="min-width: 80%;"
                    ></v-text-field>
                    <v-btn
                      small
                      class="remove-button"
                      color="white"
                      style=" height:30px; margin-top:10px; width:20% !important"
                      depressed
                      @click="deleteOption(key)"
                    >
                      <v-icon dark>
                        mdi-trash-can-outline
                      </v-icon>
                    </v-btn>
                  </div>
                </v-col>
              </v-row>
              <div
                style=""
                v-if="enableOptions.includes(type)"
                class="add-new-option"
                @click="addOption"
              >
                + Add Choice
              </div>
            </v-col>
          </v-row>
        </v-row>
      </div>
    </v-card>
  </v-container>
</template>
<script>
export default {
  props: {
    id: { type: Number, default: null },
    type: { type: String, default: "" },
    is_required: { type: String, default: "0" },
    field_name: { type: String, default: "" },
    options: {
      type: Array,
      default: () => [],
    },
  },
  components: {},
  data() {
    return {
      names: [
        { name: "full_name", value: "Full Name" },
        { name: "phone_number", value: "Phone Number" },
        { name: "email", value: "Email" },
        { name: "radio_buttons", value: "Radio Buttons" },
        { name: "check_boxes", value: "Check Boxes" },
        { name: "text_box", value: "Text Field" },
        { name: "text_area", value: "Text Area" },
        { name: "drop_down", value: "Dropdown list" },
      ],
      value: "",
      //   options: [],
      enableOptions: ["radio_buttons", "check_boxes", "drop_down"],
      disableName: ["full_name", "phone_number"],
    };
  },
  computed: {},
  mounted() {
    // if (!this.disableName.includes(this.type)) {
    //   this.value = this.field_name;
    // }
  },
  watch: {},
  methods: {
    deleteField() {
      this.$emit("deleteField");
    },
    moveUp() {
      this.$emit("moveUp");
    },
    setFieldName(val) {
      this.$emit("setFieldName", { key: this.id, value: val });
    },
    setIsRequired(val) {
      this.$emit("setIsRequired", { key: this.id, value: val });
    },
    moveDown() {
      this.$emit("moveDown");
    },
    getName() {
      let n = this.names.find((ele) => {
        return ele.name === this.type;
      });
      return n.value;
    },
    addOption() {
      this.$emit("addOption", this.id);
    },
    deleteOption(key) {
      this.$emit("deleteOption", { key: this.id, innerKey: key });
    },
  },
};
</script>
<style lang="scss" scoped>
.shadow {
  box-shadow: 0 4px 8px 0 rgba(70, 76, 136, 0.2) !important;
}
.rounded-border {
  border-radius: 10px;
}
.add-new-option {
  color: var(--main-color, #4faeaf);
  font-family: Inter;
  font-size: 16px;
  cursor: pointer;
  padding-bottom: 20px;
  width: max-content;
  font-weight: 600;
  &:hover {
    text-decoration: underline;
    text-underline-offset: 3px;
  }
}
.v-btn.remove-button {
  color: #00000040 !important;
}
</style>
