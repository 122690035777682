<template>
  <v-container fluid>
    <div class="text-lg-right d-flex align-content-center justify-end gap-x-4">
      <v-btn
        height="48"
        style="background-color: #112A46;color: white; margin-right:10px"
        elevation="0"
        @click="addDialog = true"
      >
        <v-icon dark left>mdi-plus</v-icon>
        Actions Sequence
      </v-btn>
    </div>
    <v-card class="shadow px-8 py-4 rounded-5 mt-10">
      <!-- <v-row class="mt-4">
        <div
          class="mb-2 mt-2"
          style="font-size: 25px;font-weight: 600; margin-left:15px"
        >
          Actions Sequence List
        </div>

        <v-spacer></v-spacer>
        <v-col md="2">
          <div style="width: 110px; float: right">
            <v-select
              outlined
              :menu-props="{ bottom: true, offsetY: true }"
              background-color="#fff"
              filled
              dense
              v-model="perPage"
              label="Per Page"
              :items="[10, 15, 25, 50]"
              @change="loadSequences"
            ></v-select>
          </div>
        </v-col>
      </v-row> -->

      <v-simple-table>
        <template v-slot:default>
          <thead>
            <tr class="tr-rounded tr-neon opacity-70">
              <th>
                Sequence Name
              </th>

              <th>
                Description
              </th>

              <th>
                Actions
              </th>
            </tr>
          </thead>

          <tbody>
            <tr v-for="(sequence, i) in sequences" :key="i">
              <td>
                {{ sequence.name }}
              </td>
              <td class="ellipsis-small">
                {{ sequence.description }}
              </td>

              <td>
                <!-- <v-menu offset-y>
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn
                      v-bind="attrs"
                      depressed
                      v-on="on"
                      dark
                      small
                      color="rgb(132 132 132)"
                      ><v-icon dark>mdi-dots-horizontal</v-icon></v-btn
                    >
                  </template>
                  <v-list>
                    <v-list-item @click="editSequence(sequence.id)">
                      <v-list-item-title>Edit</v-list-item-title>
                    </v-list-item>
                    <v-list-item @click="deleteSequence(sequence.id)">
                      <v-list-item-title>Change Status</v-list-item-title>
                    </v-list-item>
                  </v-list>
                </v-menu> -->

                <v-card-actions>
                  <v-btn
                    class="  small"
                    color="white"
                    @click="editSequence(sequence)"
                    depressed
                  >
                    <v-icon right dark>
                      mdi-pencil
                    </v-icon>
                  </v-btn>
                  <v-btn
                    style="padding-right: 1px;"
                    color="white"
                    class="  small"
                    @click="deleteSequence(sequence.id)"
                    depressed
                  >
                    <v-icon right dark>
                      mdi-trash-can-outline
                    </v-icon>
                  </v-btn>
                </v-card-actions>
              </td>
            </tr>
          </tbody>
        </template>
      </v-simple-table>

      <v-pagination
        v-if="totalPages > 1"
        v-model="page"
        :length="totalPages"
      ></v-pagination>

      <v-dialog v-model="addDialog" persistent width="600">
        <v-card style="margin:0 !important">
          <v-toolbar dark color="rgb(0, 176, 175)">
            <v-spacer></v-spacer>
            <v-toolbar-title>Action sequence</v-toolbar-title>
            <v-spacer></v-spacer>
            <v-btn icon dark @click="closeModal">
              <v-icon>mdi-close</v-icon>
            </v-btn>
          </v-toolbar>
          <v-card-text>
            <v-col
              cols="12"
              md="12"
              style="margin:0; margin-top:20px;padding:0"
            >
              <v-text-field
                outlined
                required
                label="Sequence name"
                background-color="#fff"
                v-model="sequence.name"
              ></v-text-field>
            </v-col>
            <v-divider horizontal />
            <v-row style="padding:0; margin-top:10px">
              <v-col
                cols="12"
                md="12"
                v-for="(step, key) in sequence.steps"
                :key="key"
              >
                <v-row style="margin:0 !important; padding:0 !important">
                  <v-col md="6" style="padding: 0;">
                    <v-text-field
                      outlined
                      dense
                      required
                      hide-details
                      label="Step"
                      background-color="#fff"
                      v-model="step.step_name"
                    ></v-text-field>
                  </v-col>
                  <v-col md="3" style="padding: 0;">
                    <v-text-field
                        class="color_code"
                        outlined
                        v-model="step.color_code"
                        background-color="#fff"
                        readonly
                        light
                        dense
                        :rules="[(v) => !!v || 'Color code is required']"
                    >
                      <template v-slot:append>
                        <v-menu
                            top
                            nudge-bottom="105"
                            nudge-left="16"
                            :close-on-content-click="false"
                        >
                          <template v-slot:activator="{ on }">
                            <div :style="swatchStyle(key)" v-on="on"/>
                          </template>
                          <v-card>
                            <v-card-text class="pa-0">
                              <v-color-picker
                                  v-model="step.color_code"
                                  flat
                                  :swatches="swatches"
                                  show-swatches
                              />
                            </v-card-text>
                          </v-card>
                        </v-menu>
                      </template>
                    </v-text-field>
                  </v-col>

                  <v-col cols="12" md="1" style="padding: 0;">
                    <v-btn
                      small
                      block
                      color="white"
                      style="color:black; height:30px;padding: 0; margin-top:5px"
                      depressed
                      @click="moveUp(key)"
                    >
                      <v-icon dark>
                        mdi-arrow-up-circle-outline
                      </v-icon>
                    </v-btn>
                  </v-col>
                  <v-col cols="12" md="1" style="padding: 0;">
                    <v-btn
                      small
                      block
                      color="white"
                      style="color:black; height:30px;padding: 0; margin-top:5px"
                      depressed
                      @click="moveDown(key)"
                    >
                      <v-icon dark>
                        mdi-arrow-down-circle-outline
                      </v-icon>
                    </v-btn>
                  </v-col>
                  <v-col cols="12" md="1" style="padding: 0;">
                    <v-btn
                        small
                      block
                      color="white"
                      style="color:black; height:30px;padding: 0; margin-top:5px"
                      depressed
                      @click="deleteStep(key)"
                    >
                      <v-icon  dark>
                        mdi-delete-circle-outline
                      </v-icon>

                    </v-btn>
                  </v-col>
                </v-row>
              </v-col>
              <div class="add-new-step" @click="addStep">
                + Add Choice
              </div>
            </v-row>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn
              @click="saveSequence()"
              class="ma-2 white--text blue-color"
              text
              >Save
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>

      <confirm-model
        v-bind="confirmModel"
        @confirm="confirmActions"
        @close="confirmModel.id = null"
      ></confirm-model>
    </v-card>
  </v-container>
</template>
<script>
import ConfirmModel from "@/components/Confirmation/ConfirmModel";
import LeadsFormConfiguration from "@/views/LeadTracking/LeadsFormConfiguration.vue";
export default {
  props: {},
  components: { ConfirmModel },
  data() {
    return {
      sequences: [],
      sequence: {
        name: null,
        steps: [
          {
            step_name: "",
            color_code: "#00AAAAFF",
          },
        ],
        deletedSteps: [],
      },
      isLoading: false,
      page: 1,
      totalPages: 1,
      perPage: 10,
      colorCodes: [
        {
          name: null,
          color_code: "#00AAAAFF",
        },
      ],
      swatches: [
        ["#FF0000", "#AA0000", "#550000"],
        ["#FFFF00", "#AAAA00", "#555500"],
        ["#00FF00", "#00AA00", "#005500"],
        ["#00FFFF", "#00AAAA", "#005555"],
        ["#0000FF", "#0000AA", "#000055"],
      ],
      confirmModel: {
        id: null,
        title: null,
        description: null,
      },
      addDialog: false,
    };
  },
  computed: {
    LeadsFormConfiguration() {
      return LeadsFormConfiguration
    }
  },
  mounted() {
    this.loadSequences();
  },
  watch: {
    page() {
      this.loadSequences();
    },
  },

  methods: {
    swatchStyle(cIndex) {
      const { color_code, menu } = this.sequence.steps[cIndex];
      return {
        backgroundColor: color_code,
        cursor: "pointer",
        height: "30px",
        width: "30px",
        borderRadius: menu ? "50%" : "4px",
        transition: "border-radius 200ms ease-in-out",
      };
    },

    deleteStep(key) {
      if (!this.sequence.deletedSteps){
        this.sequence.deletedSteps = [];
      }
      this.sequence.deletedSteps.push(this.sequence.steps[key]);
      this.sequence.steps.splice(key, 1);
    },

    moveUp(key) {
      if (this.sequence.steps.length <= 1) {
        return;
      }
      let fromIndex = key;
      let toIndex = key - 1;
      if (
        fromIndex < 0 ||
        fromIndex >= this.sequence.steps.length ||
        toIndex < 0 ||
        toIndex >= this.sequence.steps.length
      ) {
        console.error("Invalid index");
        return this.sequence.steps;
      }

      const elementToMove = this.sequence.steps.splice(fromIndex, 1)[0];
      this.sequence.steps.splice(toIndex, 0, elementToMove);
      return this.sequence.steps;
    },
    moveDown(key) {
      if (this.sequence.steps.length <= 1) {
        return;
      }
      let fromIndex = key;
      let toIndex = key + 1;
      if (
        fromIndex < 0 ||
        fromIndex >= this.sequence.steps.length ||
        toIndex < 0 ||
        toIndex >= this.sequence.steps.length
      ) {
        console.error("Invalid index");
        return this.sequence.steps;
      }

      const elementToMove = this.sequence.steps.splice(fromIndex, 1)[0];
      this.sequence.steps.splice(toIndex, 0, elementToMove);
      return this.sequence.steps;
    },

    addStep() {
      this.sequence.steps.push({
        step_name: "",
        color_code: "#00AAAAFF",
      });
    },
    closeModal() {
      this.addDialog = false;
      this.loadSequences();
      this.sequence = {
        name: null,
        steps: [
          {
            step_name: "",
            color_code: "#00AAAAFF",
          },
        ],
        deletedSteps: [],
      };
    },
    loadSequences() {
      this.showLoader("Loading");
      this.$http
        .get(`venues/leads/action-list`)
        .then((response) => {
          if (response.status == 200 && response.data.status == true) {
            if (response.data.data) {
              this.sequences = response.data.data;
            }
          }
          this.hideLoader();
        })
        .catch((error) => {
          this.hideLoader();
          this.errorChecker(error);
        });
    },
    editSequence(sequence) {
      this.sequence.id = sequence.id;
      this.sequence.name = sequence.name;
      this.sequence.steps = sequence.steps;
      this.addDialog = true;
    },
    deleteSequence(id) {
      this.confirmModel = {
        id: id,
        title: "Are you sure?",
        description: "This sequence will be deleted",
        type: "delete_confirm",
      };
    },
    deleteSequencePost(id) {
      this.$http
        .post(`venues/leads/action-list/delete/${id}`)
        .then((response) => {
          if (response.status == 200 && response.data.status == true) {
            if (response.data.data) {
              this.loadSequences();
            }
          }
          this.hideLoader();
        })
        .catch((error) => {
          this.hideLoader();
          this.errorChecker(error);
        });
    },
    saveSequence() {
      this.showLoader("Saving..");
      this.$http
        .post(`venues/leads/action-list`, this.sequence)
        .then((response) => {
          if (response.status == 200 && response.data.status == true) {
            if (response.data.data) {
              this.loadSequences();
              this.closeModal();
              this.$store.dispatch("loadActionsList");
            }
          }
          this.hideLoader();
        })
        .catch((error) => {
          this.hideLoader();
          this.errorChecker(error);
        });
    },
    confirmActions(data) {
      if (data.type == "close_confirm") {
        this.loadSequences();
      }
      if (data.type == "delete_confirm") {
        this.deleteSequencePost(data.id);
      }
      this.confirmModel.id = null;
    },
  },
};
</script>

<style scoped lang="scss">

.v-text-field--enclosed.v-input--dense:not(.v-text-field--solo).v-text-field--outlined .v-input__append-inner {
  margin-top:5px !important;
}


.tr-rounded {
  th:first-child,
  td:first-child {
    border-top-left-radius: 8px;
    border-bottom-left-radius: 8px;
  }

  th:last-child,
  td:last-child {
    border-top-right-radius: 8px;
    border-bottom-right-radius: 8px;
  }
}
.tr-neon {
  td,
  th {
    background: #4faeaf !important;
  }

  &.opacity-70 {
    td,
    th {
      background: rgba(233, 241, 246, 0.7) !important;
    }
  }
}

.shadow {
  box-shadow: 0 4px 8px 0 rgba(70, 76, 136, 0.2) !important;
}

.v-card {
  margin-top: 15px !important;
  margin-bottom: 15px !important;
  margin-right: 8px !important;
  margin-left: 8px !important;
}
.rounded-5 {
  border-radius: 1.25rem !important;
}
th {
  font-size: 11px !important;
}
.add-new-step {
  color: var(--main-color, #4faeaf);
  font-family: Inter;
  font-size: 14px;
  width: max-content;
  font-weight: 600;
  margin-left: 10px;
  cursor: pointer;
  &:hover {
    text-decoration: underline;
    text-underline-offset: 3px;
  }
}



</style>
